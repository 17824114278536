import { Typography, TypographyProps } from "@mui/material";
import React from "react";

interface TProps extends TypographyProps {
  children: React.ReactNode;
  capitalize?: boolean;
}

export const Typography12 = ({ children, ...props }: TProps) => {
  return (
    <Typography fontSize="0.875rem" fontWeight={400} mb={1} {...props}>
      {children}
    </Typography>
  );
};
export const Typography14 = ({ children, ...props }: TProps) => {
  return (
    <Typography fontSize="0.875rem" fontWeight={400} mb={1} {...props}>
      {children}
    </Typography>
  );
};
export const Typography16 = ({ children, ...props }: TProps) => {
  return (
    <Typography fontSize="1rem" fontWeight={500} {...props}>
      {children}
    </Typography>
  );
};

export const TitleTypography = ({ children, ...props }: TProps) => {
  return (
    <Typography14 color={"#676A73"} {...props}>
      {children}
    </Typography14>
  );
};

export const ValueTypography = ({
  children,
  capitalize = true,
  ...props
}: TProps) => {
  return (
    <Typography16
      color={"#1C202C"}
      textTransform={capitalize ? "capitalize" : "none"}
      {...props}
    >
      {children}
    </Typography16>
  );
};
