import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import styled from "styled-components";
import PageTitle from "./styled-components/page-title";

interface CardTitleProps {
  title: string;
  children?: ReactNode;
  pageTitle?: boolean;
}

const ResponsiveTypography = styled(Typography)`
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const SimpleCardTitle = ({ title }: CardTitleProps) => {
  return <ResponsiveTypography>{title}</ResponsiveTypography>;
};

export const EnhancedCardTitle = ({
  title,
  children,
  pageTitle,
}: CardTitleProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
      mb={8}
      gap={4}
    >
      {!pageTitle ? (
        <ResponsiveTypography>{title}</ResponsiveTypography>
      ) : (
        <PageTitle cardtitle>{title}</PageTitle>
      )}

      <Box display="flex" gap={4} justifyContent="space-between">
        {children}
      </Box>
    </Box>
  );
};
