import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  height: 100%;
`;

const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
